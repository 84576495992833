.slab-prediction-table {
  padding:0px 0px; 

  /* width */
::-webkit-scrollbar {
  width: 5px;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #DADBDD; 
  border-radius: 10px;
}

  .ant-table-thead .ant-table-cell {
    background-color: hsl(226deg 100% 97%) !important;
    color: #83878c !important;
    font-size: 13px !important;
    box-shadow: none !important;
  }
  .ant-table-body {
    background-color: #f0f3ff;
    padding-right: 5px;
  }
  .ant-table-wrapper .ant-table-thead > tr > td {
    border-bottom: none !important;
  }
  th.ant-table-cell::before {
    background-color: #f0f3ff !important;
  }
  td.ant-table-cell::before {
    background-color: #f0f3ff !important;
  }
  th.ant-table-cell {
    border-bottom: #f0f3ff !important;
  }
  // .ant-table-wrapper .ant-table-cell-scrollbar:not{
  //   box-shadow: none;
  // }
}



.slab-prediction-table-row > td:last-child {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
.slab-prediction-table-row > td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.slab-prediction-table-row {
  background-color: #ffffff !important;
  height: 90px !important;
  > td {
    border-bottom: 6px solid #f0f3ff !important;
    border-top: 6px solid #f0f3ff !important;
  }
}

.slab-prediction-table-quantity-order {
  // align-items: center;
  // justify-content: center;
  // width: 75%;
  // display: flex;
  // gap: 10px;
  font-family: "AvertaStd-Regular" !important;
  font-weight: 500;
}
.slab-prediction-table thead {
  background-color: #f1f1f1;
}
.product-card-red-dot {
  height: 4px;
  width: 4px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.product-card-yellow-dot {
  height: 4px;
  width: 4px;
  background-color: #faad14;
  border-radius: 50%;
  display: inline-block;
}
.product-card-green-dot {
  height: 4px;
  width: 4px;
  background-color: #13c26b;
  border-radius: 50%;
  display: inline-block;
}
.product-card-blue-dot {
  height: 4px;
  width: 4px;
  background-color: #007bff;
  border-radius: 50%;
  display: inline-block;
}
.slab-prediction-table-progress-bar {
  .ant-progress-bg {
    height: 4px !important;
  }
}
