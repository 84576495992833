.navbar-wrapper{
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family:"AvertaStd-Regular" !important;
    .navbar-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
    .navbar-content-wrapper{
        display: flex;
        gap: 28px;
    }
    .navbar-app-name{
        color: #1F2730;
        font-size: 24px;
        font-weight: 600;
        font-family: 'AvertaStd-Semibold' !important;
    }
    .navbar-date{
        font-size: 12px;
        color: #51575E;
    }
    .navbar-profile-notification{
        gap: 24px;
    display: flex;
    align-items: self-end;
    }
    .navbar-branch-dropdown{
        font-family: "AvertaStd-Regular" !important;
    }
}