.reset-password-wrapper{
    margin-top: 110px;
    background: #ffffff;
    width: 100%;
    border-radius: 8px;
    font-family: "AvertaStd-Regular" !important;
    .reset-password-content{
        padding-bottom: 30px;
        .reset-password-forgot-text{
            padding-top: 90px;
            padding-left: 42px;
            font-weight: 600;
            font-size:20px;
        }
        .reset-password-reset-text{
            padding-top: 16px;
                padding-left: 42px;
                color: #83878C;
                font-size: 14px;
        }
        .reset-password-email-text{
            padding-top: 40px;
            padding-left: 42px;
            color: #83878C;
            font-size: 14px;
        }
        .reset-password-email-input{
            padding-left: 42px; padding-top: 10px;
            .ant-input{
                height: 42px !important;
                width: 82% !important;
            }
            .ant-form-item .ant-form-item-control-input{
                width: 100% !important;
            }
        }

    }
}

