body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: hsl(226deg 100% 97%);
  ::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 20px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}
@font-face {
  font-family: 'AvertaStd-Regular';
  src: url('./Fonts/AvertaStd-Regular.ttf') format("truetype");
}
@font-face {
  font-family: 'AvertaStd-Bold';
  src: url('./Fonts/AvertaStd-Bold.ttf') format("truetype");
}
@font-face {
  font-family: 'AvertaStd-Semibold';
  src: url('./Fonts/AvertaStd-Semibold.ttf') format("truetype");
}
