.login-form-wrapper {
  background-color: white;
  width: 100%;
  border-radius: 8px;
  font-family: 'AvertaStd-Regular';
  .login-form-header {
    padding-top: 30px;
    padding-left: 42px;
  }
  .login-form-inputs {
    padding-top: 40px;
    padding-left: 42px;
  }
  .login-form-footer {
    padding-top: 4px;
    padding-left: 42px;
    padding-bottom: 24px;
  }
  .login-btn {
    background: #51575e;
    width: 82% !important;
    height: 50px !important;
    cursor: pointer;
  }
  .login-form-header-signin {
    color: #0f0f0f;
    font-size: 20px;
    font-weight: 600;
    font-family: 'AvertaStd-Bold';
  }
  .login-form-header-welcome-back {
    font-size: 14px;
    padding-top: 8px;
    color: #83878c;
  }
  .login-form-email-label,
  .login-form-password-label {
    font-size: 14px;
    color: #83878c;
    padding-bottom: 6px;
  }
  .login-form-forgot-password {
    text-decoration-line: underline;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    padding-top: 18px;
    width: 114px;
  }
  .ant-input {
    height: 42px !important;
    width: 100% !important;
  }
  .ant-form-item .ant-form-item-control-input {
    width: 82% !important;
  }
  .login-form-error {
    text-align: center;
    padding-bottom: 4px;
    color: #ff4d4f;
  }
}
