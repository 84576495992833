.display-selected-filter-wrapper{
    display: flex;
    gap: 10px;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    min-height: 28px;
    padding-right: 10px;
}

.display-selected-filter-product-count{
    color: #B4B7BA;
}
.display-selected-filter-product-status{
    color: #51575E;
    font-size: 14px;
}