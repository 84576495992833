.slab-prediction-form-wrapper {
  width: 100%;

  .slab-prediction-form-generate-result-btn {
    width: 100%;
    height: 50px !important;
    transition-duration: 0.4s;
    border-color: none;
    background-color: #51575e;
    color: #ffffff;
    font-family: 'AvertaStd-Regular' !important;
    margin-left: 4px;
  }
}

.slab-prediction-form-date-range-dropdown-class {
  .ant-picker-input .ant-picker-input-active {
    font-family: 'AvertaStd-Regular' !important;
  }

  .ant-picker-footer-extra {
    .slab-prediction-preset-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;

      .slab-prediction-preset {
        cursor: pointer;
      }

      .slab-prediction-preset:hover {
        color: #007bff;
      }
    }
  }
}

.slab-prediction-form-input {
  display: flex;
  gap: 28px;
  padding-top: 20px;
  justify-content: space-between;

  .slab-prediction-form-input-content {
    display: flex;
    align-items: baseline;
    gap: 8px;
    width: 100%;

    .slab-prediction-form-preset-dropdown {
      font-family: 'AvertaStd-Regular' !important;
    }

    .slab-prediction-form-preset-overlay {
      span {
        font-family: 'AvertaStd-Regular' !important;
      }
    }

    .slab-prediction-form-date-range-dropdown-class {
      .ant-picker-active-bar {
        display: none;
      }
    }
  }

  .slab-prediction-form-date-range {
    width: 100%;

    .slab-prediction-form-date-range-suffix {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .ant-picker-range {
      height: 50px !important;
    }

    .ant-picker {
      border: unset !important;
    }

    #dateRange {
      font-family: 'AvertaStd-Regular' !important;
      text-align: center;
    }

    .ant-picker-input {
      input {
        font-family: 'AvertaStd-Regular' !important;
        text-align: center;
      }
    }

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker .ant-picker-input span.ant-picker-suffix,
    .ant-picker.ant-picker-range span.ant-picker-suffix {
      margin-left: 1px;
      margin-right: 10px;
      order: -1;
    }

    .ant-picker-input {
      input {
        font-family: 'AvertaStd-Regular' !important;
        height: 42px !important;
        color: rgba(0, 0, 0, 0.88);
      }
    }
  }

  .slab-prediction-form-product-list {
    margin-left: 10px;

    .ant-select-selector {
      height: 50px !important;
      border: unset !important;
      overflow-y: scroll;
    }

    .ant-select-multiple .ant-select-selection-item {
      background: #ffffff !important;
      border-radius: 20px !important;
      border: 2px solid rgba(5, 5, 5, 0.06) !important;
    }
  }

  // .ant-select-arrow {
  //   display: none;
  // }

  .slab-prediction-form-generate-result-btn:hover {
    background-color: #007bff;
    color: #ffffff;
  }

  .csv-link:hover {
    background-color: #007bff !important;
    color: #ffffff !important;
  }

  .ant-form-item .ant-form-item-control-input {
    width: 100%;
  }

  .ant-form-item-control-input-content > .ant-picker {
    width: 100%;
  }
}

.uploaded-sheets-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 24px;
}

.date-range-text {
  font-family: 'AvertaStd-Regular' !important;
}

.uploadingFileInfo,
.processingFileInfo {
  color: #83878c !important;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: transparent;
  box-shadow: none;
}

.processingFileInfo {
  color: #faad14 !important;
  background: transparent;
  box-shadow: none;
}

.processingCompletedAlert {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;

  button > span {
    text-decoration: underline;
  }
}

.alertInfo {
  color: #83878c;
}

@media screen and (max-width: 992px) and (min-width: 340px) {
  .uploaded-sheets-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 36px;
  }

  .slab-prediction-form-input .slab-prediction-form-product-list {
    margin: 0px;
  }
  .slab-prediction-form-generate-result-btn {
    margin: 20px 0px;
  }
}
