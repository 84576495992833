.uploaded-inventory-sheet-wrapper {
  padding-top: 30px;
  width: 70%;
  border-radius: 8px;
}

.uploaded-inventory-sheet-header {
  padding: 26px 25px 8px 25px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "AvertaStd-Regular" !important;
}

.uploaded-inventory-sheet-content {
  font-size: 14px !important;
  align-items: center;
  border: 1px dashed #e5e5e7;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 56px;
  background: rgba(251, 251, 251, 0.39);
}

.uploaded-inventory-sheet-left-content {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #1f2730;
}

.uploaded-inventory-sheet-right-content {
  float: right;
  margin-top: 5px;
}

.uploaded-inventory-sheet-last-updated {
  padding-right: 10px;
  color: #faad14;
}

.uploaded-inventory-sheet-upload-btn {
  cursor: pointer;
  color: #007bff;
  text-decoration-line: underline;
}

.label-and-info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  .label-name {
    color: #51575e;
    font-family: "AvertaStd-Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
  }
}

.uploadFile {
  cursor: pointer;
  width: 100%;

  img {
    color: #83878c;
  }
}

.supportedFormInfo {
  width: 100%;
  padding-top: 10px;
  text-align: center;
  color: #83878c;
}

.selectFileContent {
  width: 80%;
  text-align: center;
  color: #83878c;
}


@media screen and (max-width: 992px) and (min-width: 340px) {

  .uploaded-inventory-sheet-wrapper {
    padding-top: 5px;
    width: 100%;
    border-radius: 8px;
  }

  .label-and-info-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .uploaded-inventory-sheet-content {
    height: auto;
    padding: 12px;
  }
}