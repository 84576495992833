.resetforgot-password-wrapper {
  margin-top: 150px;
  background: #ffffff;
  width: 440px;
  border-radius: 8px;

  .inputs-alignment-panel {
    width: 90%;
    padding: 0px 22px;
  }

  .ant-input {
    height: 42px !important;
    width: 100% !important;
  }

  .ant-form-item .ant-form-item-control-input {
    width: 100% !important;
    font-family: 'AvertaStd-Regular';
  }

  .forgot-password-email-label {
    padding-top: 40px;
    font-weight: 400;
    font-size: 20px;
    font-family: 'AvertaStd-Bold';
    text-align: center;
  }

  .forgot-password-password-label {
    padding-top: 20px;
    padding-bottom: 6px;
    color: #83878C;
    font-size: 14px;
    font-family: 'AvertaStd-Regular';
  }

  .forgot-password-reset-btn {
    width: 50%;
    margin: 0px auto 40px auto;
    height: 50px;
    transition-duration: 0.4s;
    border-color: none;
    background-color: #51575E;
    color: #FFFFFF;
    font-family: "AvertaStd-Regular" !important;
  }

  .forgot-password-reset-btn:hover {
    background-color: #007BFF;
    color: #FFFFFF;
  }

  .password-matching-text {
    color: red;
    text-align: left;
    font-family: "AvertaStd-Regular";
    width: 82%;
    padding-bottom: 15px;
  }

  .forgot-password-input-field {
    padding-top: 8px;

    .ant-form-item-control-input-content {
      input {
        font-family: "AvertaStd-Regular" !important;
      }
    }
  }
}