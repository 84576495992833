.slab-prediction-filter-wrapper{
    display: flex;
    justify-content: center;
    gap: 56px;
    padding-bottom:20px;
    padding-top:24px;
}
.slab-filter-content{
    display: flex;
    align-items: center;
    gap: 8px;
}
.slab-filter-count{
    color: #B4B7BA;
    cursor: pointer;
    font-family: 'AvertaStd-Semibold' !important;
}
.red-dot{
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.yellow-dot{
    height: 8px;
    width: 8px;
    background-color:  #FAAD14;
    border-radius: 50%;
    display: inline-block;
}
.green-dot{
    height: 8px;
    width: 8px;
    background-color: #13C26B;
    border-radius: 50%;
    display: inline-block;
}
.blue-dot{
    height: 8px;
    width: 8px;
    background-color: #007BFF;
    border-radius: 50%;
    display: inline-block;
}