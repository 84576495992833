.special-delivery-for {
    height: 48px;
    width: 341px;
    color: rgba(7, 16, 26, 0.6);
    font-family: Roboto, Sans-serif;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0.43px;
    line-height: 58px;
    margin: 0px auto;
  }
  
  .did-not-receive-email-heading {
    color: rgba(7, 16, 26, 0.5);
    font-family: Roboto, Sans-serif;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 15px;
  }
  .did-not-receive-emai {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, Sans-serif;
    font-size: 14px;
    line-height: 22px;
    margin-top: 15px;
  }
  .send-another-email {
    color: #13c26b;
    font-family: Roboto, Sans-serif;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 35px;
    cursor: pointer;
  }
  .special-delivery-img {
    margin-left: 100px;
  }
  
  .please-check-your-in {
    // height: 48px;
    width: 90%;
    color: rgba(7, 16, 26, 0.6);
    font-family: Roboto, Sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    margin: 0px auto;
  }
  