.slab-prediction-overview-wrapper{
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 22px 32px;
    align-items: center;
    font-family: "AvertaStd-Regular"
}
.slab-prediction-overview-branch{
    display:flex;
    flex-direction: column;
    gap:2px;
}
.slab-prediction-overview-stock{
    color:#83878C;
    display: flex;
    gap: 40px;
    align-items: center;
}
.slab-prediction-overview-confidence{
    display: flex;
    gap:12px;
}
.slab-prediction-overview-order-quantity{
    display:flex;
    gap:24px;
    color:#83878C;
    font-family: 'AvertaStd-Semibold';
}
.slab-prediction-overview-progress-bar{
    .ant-progress-bg{
        height: 4px !important;
    }
}